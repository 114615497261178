// © 2024 Fabio Carucci. All Rights Reserved.
import React, { useEffect, useState } from 'react'
import './App.css'
import './i18n/i18n'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Homepage from './views/homepage/Homepage'
import MaintenancePage from './views/maintenance page/MaintenancePage'
import ApartmentPage from './views/apartments page/ApartmentPage'
import { useLiveChat } from './context/LiveChatContext'
import { useCookies } from 'react-cookie'
import ReactPixel from 'react-facebook-pixel'
import { clarity } from 'react-microsoft-clarity'
import ContactUsPage from './views/contact us page/ContactUsPage'
import { useTranslation } from 'react-i18next'

/**
 * Load the chat widget script if it is not already present.
 */
const loadChatScript = () => {
  if (!document.getElementById('chat-script')) {
    const script = document.createElement('script')
    script.id = 'chat-script'
    script.src = 'https://widgets.leadconnectorhq.com/loader.js'
    script.setAttribute(
      'data-resources-url',
      'https://widgets.leadconnectorhq.com/chat-widget/loader.js'
    )
    script.setAttribute('data-widget-id', '67ae6c2289280d509e0c5f41')
    script.defer = true
    document.body.appendChild(script)
  }
}

const initializeFacebookPixel = () => {
  ReactPixel.init('1746914305844315')
  ReactPixel.grantConsent()
  ReactPixel.pageView()
}

const initializeMicrosoftClarity = () => {
  clarity.init('op82k5pufv')
  clarity.consent()
}

function App() {
  const { showLiveChat } = useLiveChat()
  const [cookies] = useCookies(['_iub_cs-s3824551'])
  const { i18n } = useTranslation()

  const cookiePolicyIds = {
    it: '66572534',
    en: '13359571',
    fr: '16339948',
    de: '17791752',
    es: '44912769',
  }

  const [clarityInitialized, setClarityInitialized] = useState(false)
  const [pixelInitialized, setPixelInitialized] = useState(false)

  // Check if the application is in maintenance mode
  const isMaintenanceMode = process.env.REACT_APP_MAINTENANCE_MODE === 'true'

  useEffect(() => {
    const cookiePolicyId = cookiePolicyIds[i18n.language.split('-')[0]] || cookiePolicyIds['it']

    // Inizializza Iubenda
    const iubendaScript = document.createElement('script')
    iubendaScript.type = 'text/javascript'
    iubendaScript.innerHTML = `
      var _iub = _iub || [];
      _iub.csConfiguration = {
        "siteId": 3824551,
        "cookiePolicyId": "${cookiePolicyId}",
        "lang": "${i18n.language.split('-')[0]}",
        "storage": { "useSiteId": true }
      };
    `
    document.body.appendChild(iubendaScript)

    const iubendaLoader = document.createElement('script')
    iubendaLoader.src = 'https://cs.iubenda.com/autoblocking/3824551.js'
    document.body.appendChild(iubendaLoader)

    const iubendaGpp = document.createElement('script')
    iubendaGpp.src = '//cdn.iubenda.com/cs/gpp/stub.js'
    document.body.appendChild(iubendaGpp)

    const iubendaCs = document.createElement('script')
    iubendaCs.src = '//cdn.iubenda.com/cs/iubenda_cs.js'
    iubendaCs.charset = 'UTF-8'
    iubendaCs.async = true
    document.body.appendChild(iubendaCs)
  }, [])

  useEffect(() => {
    const iubendaCookie = cookies['_iub_cs-s3824551']

    if (iubendaCookie) {
      loadChatScript()
      if (iubendaCookie.purposes) {
        if (iubendaCookie.purposes[4] && !clarityInitialized) {
          initializeMicrosoftClarity()
          setClarityInitialized(true)
        } else if (!iubendaCookie.purposes[4] && clarityInitialized) {
          if (window.clarity) {
            const clarityScript = document.querySelectorAll('script[src*="clarity.ms"]')
            if (clarityScript) {
              clarityScript.forEach((script) => script.remove())
            }
          }
          setClarityInitialized(false)
          console.log('Script di Microsoft Clarity eliminato correttamente')
        }

        if (iubendaCookie.purposes[5] && !pixelInitialized) {
          initializeFacebookPixel()
          setPixelInitialized(true)
        } else if (!iubendaCookie.purposes[5] && pixelInitialized) {
          if (window.fbq) {
            const fbPixelScript = document.querySelectorAll('script[src*="facebook.net"]')
            if (fbPixelScript) {
              fbPixelScript.forEach((script) => script.remove())
            }
          }
          setPixelInitialized(false)
          console.log('Script del Pixel di Facebook eliminato correttamente')
        }
      }
    } else {
      console.log('Il cookie di Iubenda non esiste ancora.')
    }
  }, [cookies['_iub_cs-s3824551']])

  /**
   * Update the visibility of the chat widget based on the provided visibility flag.
   *
   * @param {boolean} isVisible - Whether the chat widget should be visible or not.
   */
  const updateChatVisibility = (isVisible) => {
    // Function to find and handle the chat widget element
    const handleChatWidget = () => {
      const chatWidgetHost = document.querySelector(
        'chat-widget[widget-id="67ae6c2289280d509e0c5f41"]'
      )
      if (chatWidgetHost && chatWidgetHost.shadowRoot) {
        const chatWidget = chatWidgetHost.shadowRoot.querySelector('#lc_text-widget')
        if (chatWidget) {
          chatWidget.querySelector('#lc_text-widget--btn').style.bottom = '6px'
          chatWidget.style.display = isVisible ? 'block' : 'none'
          observer.disconnect() // Disconnect observer once element is found
        }
      }
    }

    // Use MutationObserver to watch for changes in the DOM
    const observer = new MutationObserver(() => handleChatWidget())

    // Start observing the document body for DOM changes
    observer.observe(document.body, { childList: true, subtree: true })

    // Perform an initial check
    handleChatWidget()
  }

  useEffect(() => {
    // Update the chat widget visibility based on the current state and cookie consent
    updateChatVisibility(showLiveChat && cookies['_iub_cs-s3824551'])
  }, [showLiveChat, cookies['_iub_cs-s3824551']])

  return (
    <div>
      {isMaintenanceMode ? (
        // Render the MaintenancePage component if in maintenance mode
        <MaintenancePage />
      ) : (
        <>
          <Router>
            <Routes>
              <Route path="/" element={<Homepage />} />
              <Route path="/apartment/:id" element={<ApartmentPage />} />
              <Route path="/contact" element={<ContactUsPage />} />
            </Routes>
          </Router>
        </>
      )}
    </div>
  )
}

export default App
