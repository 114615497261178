import React, { useContext, useState, useEffect, useRef } from 'react'
import './Apartments.css'
import { Row, Col } from 'react-bootstrap'
import ApartmentCard from './ApartmentCard'
import { FiltersContext } from '../../context/FiltersContext'
import MapComponent from '../map/MapComponent'
import { useTranslation } from 'react-i18next'
import { useApartments } from '../../context/ApartmentsContext'
import useFilteredApartments from '../../hooks/useFilteredApartments'

export default function ApartmentsList({ selectedOption, isMapView }) {
  const { t } = useTranslation()

  // Access filter states from the FiltersContext
  const { selectedStates, adults, childrens, pets } = useContext(FiltersContext)
  const totalGuests = adults + childrens // Calculate total number of guests

  const { apartments } = useApartments()

  const sortedApartments = useFilteredApartments(apartments, {
    selectedStates,
    totalGuests,
    pets,
    selectedOption,
  })

  // Use state to track the number of apartments to display (only for list view)
  const [visibleApartments, setVisibleApartments] = useState(6)

  // Create a ref for the last apartment card (for infinite scroll)
  const lastApartmentRef = useRef(null)

  // Get the apartments to display based on the visibleApartments state
  const apartmentsToDisplay = isMapView
    ? sortedApartments // Show all apartments if in map view
    : sortedApartments.slice(0, visibleApartments) // Show limited apartments in list view

  useEffect(() => {
    if (!isMapView) {
      const currentLastApartment = lastApartmentRef.current

      if (currentLastApartment) {
        const observer = new IntersectionObserver(
          (entries) => {
            entries.forEach((entry) => {
              if (entry.isIntersecting) {
                setVisibleApartments((prev) => prev + 6)
              }
            })
          },
          {
            root: null,
            rootMargin: '100px',
            threshold: 0.5,
          }
        )

        observer.observe(currentLastApartment)

        return () => {
          observer.unobserve(currentLastApartment)
          observer.disconnect()
        }
      }
    }
  }, [apartmentsToDisplay, isMapView])

  // Render MapComponent if isMapView is true, otherwise render the list of apartments
  return (
    <>
      {isMapView ? (
        // Render map view if isMapView is true
        <MapComponent apartments={apartmentsToDisplay} />
      ) : apartmentsToDisplay.length > 0 ? (
        <Row className="apartments-list">
          {apartmentsToDisplay.map((apartment, index) => {
            const isLastVisible =
              index === apartmentsToDisplay.length - 1 &&
              apartmentsToDisplay.length < sortedApartments.length

            return (
              <Col
                key={apartment.id}
                xs={12}
                md={6}
                lg={4}
                ref={isLastVisible ? lastApartmentRef : null}
              >
                <ApartmentCard
                  id={apartment.id}
                  city={apartment.city}
                  name={apartment.name}
                  beds={apartment.beds}
                  bathrooms={apartment.bathrooms}
                  guests={apartment.guests}
                  images={apartment.images}
                  price={apartment.price}
                />
              </Col>
            )
          })}
        </Row>
      ) : (
        <div className="no-results">{t('no_results')}</div>
      )}
    </>
  )
}
